import { CustomerCategoryApis } from "@root/core/api/customer-categories/customer-category.apis";
import { DemographicQuestionsApis } from "@root/core/api/demographic-questions/demographic-questions.apis";
import { DocumentApis } from "@root/core/api/documents/document.apis";
import { FollowUpStatusApis } from "@root/core/api/follow-up-status/follow-up-status.apis";
import { FollowUpTaskApis } from "@root/core/api/follow-up-tasks/follow-up-task.apis";
import { MarketingApis } from "@root/core/api/marketing-questions/marketing-questions.apis";
import { RoomApis } from "@root/core/api/rooms/room.apis";
import { environment } from "src/environments/environment";

export const APIS = {
  getLoggedInUser: environment.apiUrl + "Users/GetLoggedIn",
  timezones: {
    getSystemTimeZones: environment.apiUrl + "Timezones/ListSystemTimezones",
  },
  organisation: {
    deleteDivision: environment.apiUrl + "Organizations/DeleteDivision",
    getDetails: environment.apiUrl + "Organizations/GetOrganisationDetails",
    patchDetails: environment.apiUrl + "Organizations/PatchOrganisationDetails",
    transferOwnership: environment.apiUrl + "Organizations/UpdateOwnership",
    getLanguageSystemConfiguration: environment.apiUrl + "Organizations/GetLanguageSystemConfiguration",
    getGeneralSystemDefaultSettings: environment.apiUrl + "Organizations/GetGeneralSystemDefaultSettings",
    patchGeneralSystemDefaultSettings: environment.apiUrl + "Organizations/PatchGeneralSystemDefaultSettings",
    patchLanguageSystemConfiguration: environment.apiUrl + "Organizations/PatchLanguageSystemConfiguration",
    toggleAllowedLanguageActiveInEnder: environment.apiUrl + "Organizations/ToggleAllowedLanguageActiveInEndUser",
    deleteAllowedLanguage: environment.apiUrl + "Organizations/RemoveAllowedLanguage",
    addAllowedLanguages: environment.apiUrl + "Organizations/AddAllowedLanguages",
    organizationStyling: environment.apiUrl + "Organizations/OrganizationStyling",
    getFollowUpStatusesLookups: environment.apiUrl + "Organizations/LookUpOrganisationFollowUpStatus",
    getFollowUpStatusesTasksLookups: environment.apiUrl + "Organizations/LookUpOrganisationFollowUpTasks",
    getMarketingQuestionsLookups: environment.apiUrl + "Organizations/LookUpOrganisationMarketingQuestions",
    getCommunicationSettings: environment.apiUrl + "Organizations/OrganizationCommunicationSettings",
    patchCommunicationSettings: environment.apiUrl + "Organizations/PatchOrganizationCommunicationSettings",
    updateStyling: environment.apiUrl + "Organizations/UpdateOrganizationStyling",
  },
  divisions: {
    getAll: environment.apiUrl + "Divisions/GetAll",
    create: environment.apiUrl + "Divisions/CreateDivision",
    listAll: environment.apiUrl + "Divisions/ListAll",
    getById: environment.apiUrl + "Divisions/Get",
    updateById: (id: number) => environment.apiUrl + `Divisions/Put/${id}`,
    getProperties: environment.apiUrl + "Divisions/GetDivisionProperties",
    patchById: environment.apiUrl + `Divisions/PatchDivisionDetails`,
  },
  companies: {
    create: environment.apiUrl + "Companies/Create",
    update: (id: number) => environment.apiUrl + `Companies/UpdateCompany/${id}`,
    getAll: environment.apiUrl + "Companies/GetAllCompanies",
    getProperties: environment.apiUrl + `Companies/GetCompanyProperties`,
    getDetails: (id: number) => environment.apiUrl + `Companies/GetCompanyDetails/${id}`,
    patchDetails: (id: number) => environment.apiUrl + `Companies/PatchCompanyDetails/${id}`,
    delete: (id: number) => environment.apiUrl + `Companies/DeleteCompany/${id}`,
  },
  lookups: {
    getDivisions: environment.apiUrl + "Divisions/GetDivisionLookups",
    getUsers: environment.apiUrl + "Users/GetUsersLookup",
    getCompanies: environment.apiUrl + "Companies/ListAll",
    getHousingTypeLookup: environment.apiUrl + `Units/GetUnitHousingTypeLookup`,
    getUnitTypesLookup: environment.apiUrl + `Units/GetUnitTypesLookup`,
    getUnitSalesTypeLookup: environment.apiUrl + `Units/GetUnitSalesTypeLookup`,
    getUnitFacilityLocationsLookup: environment.apiUrl + "UnitFacilities/GetUnitFacilityLocations",
    getUnitFacilityCategoriesLookup: environment.apiUrl + "UnitFacilities/GetUnitFacilityCategories",
    getAllCurrenciesLookup: environment.apiUrl + "Currencies/GetAllCurrencies",
    getRoomNames: environment.apiUrl + "Rooms/GetRoomNames",
    getBalconyTypes: environment.apiUrl + "Balconies/GetBalconyTypes",
    getTerraceTypes: environment.apiUrl + "Terraces/GetTerraceTypes",
    allAgents: environment.apiUrl + `Users/GetAgentsAssignedToEvents`,
  },
  properties: {
    create: environment.apiUrl + "Properties/Create",
    getAll: environment.apiUrl + "Properties/GetAll",
    getPropertiesByCompanies: environment.apiUrl + "Companies/GetCompanyProperties",
    favouriteProperty: environment.apiUrl + "Properties/FavouriteProperty",
    getPropertiesLookup: environment.apiUrl + "Properties/GetPropertiesLookup",
    getPropertiesHierarchy: environment.apiUrl + "Properties/GetPropertiesHierarchy",
    listPropertyPetTypePolicies: (id?: number) =>
      environment.apiUrl + `Properties/ListPropertyPetTypes/${id}/pet-type-policy`,
    getPropertyPetTypePolicy: (propertyId: number, petTypePolicyId: number) =>
      environment.apiUrl + `Properties/GetPropertyPetType/${propertyId}/pet-type-policy/${petTypePolicyId}`,
    updatePropertyPetTypePolicy: (propertyId: number, petTypePolicyId: number) =>
      environment.apiUrl + `Properties/EditPropertyPetTypePolicy/${propertyId}/pet-type-policy/${petTypePolicyId}`,
    deletePropertyPetTypePolicy: (propertyId: number, petTypePolicyId: number) =>
      environment.apiUrl + `Properties/DeletePropertyPetType/${propertyId}/pet-type-policy/${petTypePolicyId}`,
    togglePropertyPetTypePolicy: (propertyId: number, petTypePolicyId: number) =>
      environment.apiUrl +
      `Properties/TogglePropertyPetTypePolicyActive/${propertyId}/pet-type-policy/${petTypePolicyId}`,
    getPropertyPetPolicy: (id: number) => environment.apiUrl + `Properties/GetPropertyPetPolicy/${id}/pet-policy`,
    getPropertyDetails: (id: string) => environment.apiUrl + `Properties/GetPropertyDetails/${id}`,
    patchPropertyPetPolicy: (id: number) =>
      environment.apiUrl + `Properties/PatchPropertyPetPolicy/${id}/pet-type-policy`,
    updatePropertyDetails: (id: string) => environment.apiUrl + `Properties/PatchPropertyDetails/${id}`,
    reactivatePropertySettings: (id: string) => environment.apiUrl + `Properties/ReactivatePropertySetting/${id}`,
    updateSelectedCurrencies: (id: string) => environment.apiUrl + `Properties/UpdateOtherCurrencies/${id}`,
    changeCompany: (id: string) => environment.apiUrl + `Properties/ChangeCompany/${id}`,
    addOrRemoveSecondaryAddress: (id: string) => environment.apiUrl + `Properties/AddOrRemoveSecondaryAddress/${id}`,
    getPropertyUnitStatusOverview: (id: string) =>
      environment.apiUrl + `Properties/GetPropertyUnitStatusOverview/${id}`,
    addPropertyPetTypePolicy: (propertyId: number, petPolicyId: number) =>
      environment.apiUrl + `/Properties/AddPropertyPetTypePolicy/${propertyId}/pet-policy/${petPolicyId}`,
    getPropertyPetTypesAssignedIds: (propertyId: number, petPolicyId: number) =>
      environment.apiUrl + `/Properties/GetPropertyPetPolicyPetTypeIds/${propertyId}/pet-policy/${petPolicyId}`,
    deleteProperty: (id: number) => environment.apiUrl + `Properties/DeleteProperty/${id}`,
    uploadMedia: (id: number) => environment.apiUrl + `Properties/CreatePropertyMedia/${id}`,
    propertyMediaOverview: (id: number) => environment.apiUrl + `Properties/ListPropertyMedia/${id}`,
    getPropertyMediaDetailsByMediaId: (propertyId: number, mediaId: number) =>
      environment.apiUrl + `Properties/GetPropertyMedia/${propertyId}/property-media/${mediaId}`,
    editPropertyMediaDetailsByMediaId: (propertyId: number, mediaId?: number) =>
      environment.apiUrl + `Properties/PatchPropertyMedia/${propertyId}/property-media/${mediaId ?? ""}`,
    listInvitationDocuments: (eventInstanceId: number) =>
      environment.apiUrl + `Properties/ListInvitationDocuments/Events/${eventInstanceId}`,
    propertyTexts: {
      getAll: environment.apiUrl + "TextFields/List",
      delete: (propertyId: number, textId: number) =>
        environment.apiUrl + `TextFields/DeletePropertyText/${propertyId}/${textId}`,
      duplicate: (propertyId: number, textId: number) =>
        environment.apiUrl + `TextFields/DuplicatePropertyText/${propertyId}/${textId}`,
      getTextPlacements: environment.apiUrl + "TextFields/GetTextPlacements",
      getLanguageSystemConfiguration: environment.apiUrl + "Organizations/GetLanguageSystemConfiguration",
      createPropertyText: (propertyId: number) => environment.apiUrl + `TextFields/CreatePropertyText/${propertyId}`,
      editPropertyText: (propertyId: number, textFieldId: number) =>
        environment.apiUrl + `TextFields/EditPropertyText/${propertyId}/${textFieldId}`,
      getTextField: (textFieldId: number) => environment.apiUrl + `TextFields/Get/${textFieldId}`,
    },
  },
  propertyFacilities: {
    getAll: environment.apiUrl + "PropertyFacilities/SearchFacilities",
    getAssigned: environment.apiUrl + `PropertyFacilities/GetAssignedPropertyFacilities`,
    getAssignedIds: (id: number) => environment.apiUrl + `PropertyFacilities/GetAssignedPropertyFacilityIds/${id}`,
    getDetails: environment.apiUrl + "PropertyFacilities/GetAssignedPropertyFacilityDetails",
    getCategories: environment.apiUrl + "PropertyFacilities/GetAllPropertyFacilityCategories",
    add: (id: number) => environment.apiUrl + `PropertyFacilities/CreateAssignedPropertyFacilities/${id}`,
    edit: environment.apiUrl + "PropertyFacilities/updateAssignedPropertyFacility",
    toggleActive: environment.apiUrl + "PropertyFacilities/ToggleAssignedPropertyFacility",
  },
  propertyEvents: {
    getUsersWithAccessToProperty: (propertyId: number) =>
      environment.apiUrl + `Properties/GetUsersWithAccessToProperty/${propertyId}/Users`,
    getUsersWithAccessToPropertyLookups: (propertyId: number) =>
      environment.apiUrl + `Properties/GetUsersWithAccessToPropertyLookup/${propertyId}/Users/Lookup`,
    create: (propertyId: number) => environment.apiUrl + `Properties/CreateEvent/${propertyId}/Events`,
    getAppointments: (propertyId: number) =>
      environment.apiUrl + `Properties/GetEventAppointments/${propertyId}/Events`,
    toggleAttendance: (propertyId: number, appointmentId: number) =>
      environment.apiUrl + `Properties/ToggleAttendance/${propertyId}/Events/${appointmentId}`,
    cancelAppointment: (propertyId: number, appointmentId: number) =>
      environment.apiUrl + `Properties/CancelAppointment/${propertyId}/Events/${appointmentId}`,
    getAttendedCustomers: (propertyId: number, eventInstanceId: number) =>
      environment.apiUrl + `Properties/GetAttendedCustomers/${propertyId}/Events/${eventInstanceId}`,
    toggleFavouriteCustomer: environment.apiUrl + `Users/FavouriteCustomer`,
    listEventCustomers: (propertyId: number, eventInstanceId: number) =>
      environment.apiUrl + `Properties/ListEventCustomers/${propertyId}/Events/${eventInstanceId}`,
    getAllEvents: (id: number) => environment.apiUrl + `Properties/GetEvents/${id}/Events`,
    getEventDetails: (propertyId: number, eventId: number) =>
      environment.apiUrl + `Properties/GetEventDetails/${propertyId}/Events/${eventId}`,
    getEventInstances: (propertyId: number, eventId: number) =>
      environment.apiUrl + `Properties/GetEventInstances/${propertyId}/Events/${eventId}`,
    getTimeslotAppointment: (propertyId: number) =>
      environment.apiUrl + `Properties/GetTimeslotAppointments/${propertyId}/Events`,
    getPropertyEventById: (propertyId: number, eventId: number) =>
      environment.apiUrl + `Properties/GetEventFullDetails/${propertyId}/Events/${eventId}`,
    editPropertyEventById: (propertyId: number, eventId: number) =>
      environment.apiUrl + `Properties/EditEvent/${propertyId}/Events/${eventId}`,
    getUnitsAssignedToEvents: environment.apiUrl + "Units/GetUnitsAssignedToEvents",
    stopRepetitionOnEvent: (propertyId: number, eventId: number) =>
      environment.apiUrl + `Properties/StopRepititions/${propertyId}/Events/${eventId}`,
    getEventCustomers: (propertyId: number, eventId: number) =>
      environment.apiUrl + `Properties/ListEventCustomers/${propertyId}/Events/${eventId}`,
    getAvailableTimeSlot: (propertyId: number, eventInstanceId: number) =>
      environment.apiUrl + `Properties/GetAvailableTimeslots/${propertyId}/Events/${eventInstanceId}`,
    changeTimeSlot: (propertyId: number, eventInstanceId: number) =>
      environment.apiUrl + `Properties/ChangeEventTimeslot/${propertyId}/Events/${eventInstanceId}`,
  },
  packages: {
    createFacilityPackage: environment.apiUrl + "FacilityPackages/CreatePackage",
    getFacilitiesPackageList: environment.apiUrl + "FacilityPackages/GetPackages",
    deletePackage: (id: number) => environment.apiUrl + `FacilityPackages/DeletePackage/${id}`,
    duplicatePackage: (id: number) => environment.apiUrl + `FacilityPackages/DuplicatePackage/${id}`,
    editFacilityPackage: (id: number) => environment.apiUrl + `FacilityPackages/PatchPackage/${id}`,
    getFacilityPackageDetails: (id: number) => environment.apiUrl + `FacilityPackages/GetPackageDetails/${id}`,
    getPropertiesForLinking: environment.apiUrl + "Divisions/GetPropertiesForLinking",
    getPropertyMediaPackages: (id: number) => environment.apiUrl + `Properties/ListPropertyMediaPackages/${id}`,
    createPropertyMediaPackage: (id: number) => environment.apiUrl + `/Properties/CreatePropertyMediaPackage/${id}`,
    getPropertyMediaPackageDetails: (propertyId: number, packageId: number) =>
      environment.apiUrl + `Properties/GetPropertyMediaPackageById/${propertyId}/${packageId}`,
    editPropertyMediaPackage: (propertyId: number, packageId: number) =>
      environment.apiUrl + `Properties/EditPropertyMediaPackage/${propertyId}/${packageId}`,
    deletePropertyMediaPackage: (propId: number, mediaPackageId: number) =>
      environment.apiUrl + `Properties/DeletePropertyMediaPackage/${propId}/${mediaPackageId}`,
    duplicatePropertyMediaPackage: (propertyId: number, mediaPackageId: number) =>
      environment.apiUrl + `Properties/DuplicateMediaPackage/${propertyId}/DuplicateMediaPackage/${mediaPackageId}`,
  },
  linking: {
    facilityPackages: {
      updateLinkedUnits: (id: number) => environment.apiUrl + `FacilityPackages/PatchPackage/${id}`,
      listLinkedUnits: (propertyId: number, facilityPackageId: number) =>
        environment.apiUrl + `FacilityPackages/ListLinkedUnits/${propertyId}/Package/${facilityPackageId}`,
    },
    mediaPackages: {
      updateLinkedUnits: (propertyId: number, mediaPackageId: number) =>
        environment.apiUrl + `Properties/PatchMediaPackage/${propertyId}/media-Package/${mediaPackageId}`,
      listLinkedUnits: (propertyId: number, mediaPackageId: number) =>
        environment.apiUrl + `MediaPackages/ListLinkedUnits/${propertyId}/Package/${mediaPackageId}`,
    },
    textPackages: {
      updateLinkedUnits: (propertyId: number, textPackageId: number) =>
        environment.apiUrl + `TextFields/PatchPropertyPackage/${propertyId}/Package/${textPackageId}`,
      listLinkedUnits: (propertyId: number, textPackageId: number) =>
        environment.apiUrl + `TextFields/ListLinkedUnits/${propertyId}/Package/${textPackageId}`,
    },
  },
  cities: {
    getAll: environment.apiUrl + "Cities/GetCities",
  },
  teams: {
    getAll: environment.apiUrl + "Teams/GetAll",
    createTeam: environment.apiUrl + "teams/Create",
    getTeamById: environment.apiUrl + "Teams/Get",
    reactivate: (id: number) => environment.apiUrl + `Teams/Activate/${id}`,
    deactivate: (id: number) => environment.apiUrl + `Teams/Deactivate/${id}`,
    updateTeam: (id: number) => environment.apiUrl + `Teams/Put/${id}`,
    assignProperties: (id: number) => environment.apiUrl + `Teams/AssignProperties/${id}`,
    replaceTeamManger: environment.apiUrl + "Teams/ReplaceTeamManager",
  },
  addresses: {
    getAddress: environment.apiUrl + "Addresses/GetAddressFromVat",
  },
  mediaPackages: {
    duplicatePackage: environment.apiUrl + "MediaPackages/DuplicatePackage",
  },

  getAllUserRoles: environment.apiUrl + "Roles/GetAllRoles",
  getAllLanguages: environment.apiUrl + "Countries/GetLanguages",
  getAllCountries: environment.apiUrl + "Countries/GetAll",
  switchDivision: environment.apiUrl + "Users/SwitchDivision",
  user: {
    createUser: environment.apiUrl + "Users/Create",
    editUser: environment.apiUrl + "Users/EditUser",
    getAllUsers: environment.apiUrl + "Users/GetAll",
    restore: (id: number) => environment.apiUrl + `Users/ReactivateUser/${id}`,
    archive: (id: number) => environment.apiUrl + `Users/ArchiveUser/${id}`,
    block: (id: number) => environment.apiUrl + `Users/BlockUserLogin/${id}`,
    unblock: (id: number) => environment.apiUrl + `Users/UnblockUserLogin/${id}`,
    editUserPropertyAccess: environment.apiUrl + "Users/EditPropertyAccess",
    getUserById: (id: number) => environment.apiUrl + `Users/GetUserDetails/${id}`,
    resendInvitation: (id: number) => environment.apiUrl + `Users/ReinviteUser/${id}`,
    getSystemAdminsInOrganisation: environment.apiUrl + "Users/GetSystemAdminsInOrganisation",
  },
  document: DocumentApis,
  import: {
    downloadTemplate: environment.apiUrl + "Units/DownloadTemplate",
    parseUnits: environment.apiUrl + "Units/ParseUnits",
    createUnits: environment.apiUrl + "Units/CreateUnits",
  },
  currency: {
    getAvailableCurrencies: environment.apiUrl + `Currencies/GetAvailableCurrencyConversions`,
  },
  unit: {
    getEconomyDetailsForUnit: environment.apiUrl + `Units/GetUnitEconomy`,
    updateUnitEconomyField: (id: string) => environment.apiUrl + `Units/PatchUnitEconomy/${id}`,
    getUnitDetails: (id: string) => environment.apiUrl + `Units/GetDetails/${id}`,
    updateUnitDetails: (id: string) => environment.apiUrl + `Units/PatchUnitDetails/${id}`,
    updateUnitTermsAndConditions: (id: string) => environment.apiUrl + `Units/PatchUnitTermsAndConditions/${id}`,
    getTermsAndConditions: environment.apiUrl + `Units/GetUnitTermsAndConditions`,
    getHiddenUnitInputs: (id: string) => environment.apiUrl + `Units/GetUnitHiddenInputs/${id}`,
    getUnitLayout: (id: string) => environment.apiUrl + `Units/GetUnitLayout/${id}`,
    patchUnitLayout: (id: string) => environment.apiUrl + `Units/PatchUnitLayout/${id}`,
    getPetPolicies: environment.apiUrl + `Units/GetPetTypePolicies`,
    updateUnitPetTypePolicy: (id: string) => environment.apiUrl + `Units/UpdatePetTypePolicies/${id}`,
    getUnitsInProperty: environment.apiUrl + `Units/ListUnitsInProperty`,
    toggleFavoriteUnit: (id: string) => environment.apiUrl + `Units/FavoriteUnit/${id}`,
    toggleAdvertiseUnit: (id: string) => environment.apiUrl + `Units/ToggleAdvertiseUnit/${id}`,
    makeUnitAvailable: (id: string) => environment.apiUrl + `Units/ActivateUnit/${id}`,
  },
  unitFacilities: {
    get: (unitId: string, facilityId: number) =>
      environment.apiUrl + `Units/${unitId}/AssignedUnitFacilities/${facilityId}`,
    getAll: environment.apiUrl + "UnitFacilities/GetUnitFacilities",
    getAssigned: (id: string) => environment.apiUrl + `Units/ListUnitFacilities/${id}`,
    getPropertyFacilities: (id: string) => environment.apiUrl + `Units/ListUnitPropertyFacilities/${id}`,
    toggleUnitFacility: (unitId: string, facilityId: number) =>
      environment.apiUrl + `Units/${unitId}/AssignedUnitFacilities/${facilityId}/Toggle`,
    toggleUnitPropertyFacility: (unitId: string, facilityId: number) =>
      environment.apiUrl + `AssignedUnitPropertyFacilities/TogglePropertyFacilityActive/${unitId}/${facilityId}`,
    getOtherFacilities: (id: string) => environment.apiUrl + `Units/GetUnitOtherFacilities/${id}`,
    patchOtherFacilities: (unitId: string, facilityId: number) =>
      environment.apiUrl + `Units/PatchUnitOtherFacilities/${unitId}/${facilityId}`,
    getAssignedIds: (id: string) => environment.apiUrl + `Units/GetUnitAssignedFacilitiesIds/${id}`,
    add: (id: string) => environment.apiUrl + `Units/${id}/AssignedUnitFacilities`,
    updateAssigned: (unitId: string, facilityId: number) =>
      environment.apiUrl + `Units/${unitId}/AssignedUnitFacilities/${facilityId}/Edit`,
  },
  petTypes: {
    getPetTypes: environment.apiUrl + "PetTypes/GetPetTypes",
    getPetTypeSpecies: environment.apiUrl + "PetTypes/GetPetTypeSpecies",
  },
  journeys: {
    getAll: environment.apiUrl + "Journeys/ListJourneys",
    getFollowUp: (id: number) => environment.apiUrl + `Journeys/GetJourneyFollowUp/${id}`,
    patchFollowUp: (id: number) => environment.apiUrl + `Journeys/PatchJourney/${id}`,
    selectMarketingQuestion: (id: number) => environment.apiUrl + `Journeys/SelectMarketingQuestion/${id}`,
    createFollowUpTask: (id: number) => environment.apiUrl + `Journeys/CreateFollowUpTask/${id}`,
    getAvailableResponsibleAgentsLookups: (id: number) =>
      environment.apiUrl + `Journeys/GetAvailableResponsibleAgentsLookup/${id}`,
  },
  rooms: RoomApis,
  events: {
    getAllEvents: (id: number) => environment.apiUrl + `Properties/GetEvents/${id}/Events`,
  },
  legalEntities: {
    getCustomerDetails: (id: number) => environment.apiUrl + `LegalEntities/GetCustomerDetails/${id}`,
    patchCustomerDetails: (id: number) => environment.apiUrl + `LegalEntities/PatchCustomerDetails/${id}`,
    updateLegalEntity: (id: number) => environment.apiUrl + `LegalEntities/UpdateLegalEntity/${id}`,
    favouriteCustomer: environment.apiUrl + "Users/FavouriteCustomer",
    inviteCustomerOnsite: environment.apiUrl + "LegalEntities/RegisterCustomerToEvent",
    match: environment.apiUrl + "LegalEntities/MatchLegalEntity",
    emails: {
      getEmailTemplate: (id: number) => environment.apiUrl + `Emails/GetEmailTemplate/${id}`,
    },
    searchCustomers: environment.apiUrl + `LegalEntities/SearchLegalEntities`,
  },
  marketingQuestions: MarketingApis,
  customerCategories: CustomerCategoryApis,
  followUpStatus: FollowUpStatusApis,
  followUpTasks: FollowUpTaskApis,
  demographicQuestions: DemographicQuestionsApis,
};
