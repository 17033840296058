export enum TableHeaderType {
  Text,
  Toggle,
  Checkbox,
  Image,
  Date,
  TextWithIcon,
  TextWithImageOrFirstLetter,
  TextWithPopup,
  Radio,
  RadioGroup,
  Checkmark,
  ViewDocument,
  FileType,
  ImageWithHeaderAndSubHeader,
  OverViewStatus,
  TextWithHeartAndVisibleIcon,
  StatusAndSubStatus,
  TwoColumnRow,
  PriceRow,
  StatusWithText,
  FavouriteToggle,
  ContractStatus,
  Boolean,
  TextWithExternalTitles,
}
