<svg
  [attr.height]="heightPx()"
  [attr.width]="widthPx()"
  [ngClass]="appliedClasses()"
  class="fill-primary"
  preserveAspectRatio="xMidYMid"
  style="margin: auto; background: transparent; display: block"
  viewBox="0 0 100 100"
  xmlns="http://www.w3.org/2000/svg"
>
  <g>
    <circle cx="81.734" cy="74.35" r="10">
      <animateTransform
        attributeName="transform"
        begin="0s"
        calcMode="spline"
        dur="1.4925373134328357s"
        keySplines="0.5 0 0.5 1"
        repeatCount="indefinite"
        times="0;1"
        type="rotate"
        values="0 50 50;360 50 50"
      ></animateTransform>
    </circle>
    <circle cx="74.35" cy="81.734" r="10">
      <animateTransform
        attributeName="transform"
        begin="-0.062s"
        calcMode="spline"
        dur="1.4925373134328357s"
        keySplines="0.5 0 0.5 1"
        repeatCount="indefinite"
        times="0;1"
        type="rotate"
        values="0 50 50;360 50 50"
      ></animateTransform>
    </circle>
    <circle cx="65.307" cy="86.955" r="10">
      <animateTransform
        attributeName="transform"
        begin="-0.125s"
        calcMode="spline"
        dur="1.4925373134328357s"
        keySplines="0.5 0 0.5 1"
        repeatCount="indefinite"
        times="0;1"
        type="rotate"
        values="0 50 50;360 50 50"
      ></animateTransform>
    </circle>
    <circle cx="55.221" cy="89.658" r="10">
      <animateTransform
        attributeName="transform"
        begin="-0.187s"
        calcMode="spline"
        dur="1.4925373134328357s"
        keySplines="0.5 0 0.5 1"
        repeatCount="indefinite"
        times="0;1"
        type="rotate"
        values="0 50 50;360 50 50"
      ></animateTransform>
    </circle>
    <circle cx="44.779" cy="89.658" r="10">
      <animateTransform
        attributeName="transform"
        begin="-0.25s"
        calcMode="spline"
        dur="1.4925373134328357s"
        keySplines="0.5 0 0.5 1"
        repeatCount="indefinite"
        times="0;1"
        type="rotate"
        values="0 50 50;360 50 50"
      ></animateTransform>
    </circle>
    <circle cx="34.693" cy="86.955" r="10">
      <animateTransform
        attributeName="transform"
        begin="-0.312s"
        calcMode="spline"
        dur="1.4925373134328357s"
        keySplines="0.5 0 0.5 1"
        repeatCount="indefinite"
        times="0;1"
        type="rotate"
        values="0 50 50;360 50 50"
      ></animateTransform>
    </circle>
    <circle cx="25.65" cy="81.734" r="10">
      <animateTransform
        attributeName="transform"
        begin="-0.375s"
        calcMode="spline"
        dur="1.4925373134328357s"
        keySplines="0.5 0 0.5 1"
        repeatCount="indefinite"
        times="0;1"
        type="rotate"
        values="0 50 50;360 50 50"
      ></animateTransform>
    </circle>
    <circle cx="18.266" cy="74.35" r="10">
      <animateTransform
        attributeName="transform"
        begin="-0.437s"
        calcMode="spline"
        dur="1.4925373134328357s"
        keySplines="0.5 0 0.5 1"
        repeatCount="indefinite"
        times="0;1"
        type="rotate"
        values="0 50 50;360 50 50"
      ></animateTransform>
    </circle>
    <animateTransform
      attributeName="transform"
      calcMode="spline"
      dur="1.4925373134328357s"
      keySplines="0.5 0 0.5 1"
      repeatCount="indefinite"
      times="0;1"
      type="rotate"
      values="0 50 50;0 50 50"
    ></animateTransform>
  </g>
</svg>
