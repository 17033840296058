import { Component, effect, EffectRef, signal, WritableSignal } from "@angular/core";
import { IPaginationSortPayload } from "../../interfaces/pagination-sort-payload.interface";
import { ITableAction } from "../../interfaces/table-action.interface";
import { ITableHeader } from "../../interfaces/table-header.interface";
import { ITableSortingFilter } from "../../interfaces/table-sorting-filter.interface";
import { constructInitialSignalPaginatedResponse } from "../../utilities/signals.utilities";

@Component({
  standalone: true,
  template: "",
  styles: "",
})
export abstract class BasePaginatedTableWithSearchComponent<T = number, PType = IPaginationSortPayload> {
  // This component should be inherited by components that contain a table with pagination & search
  actions: ITableAction<T>[] = [];
  abstract headers: ITableHeader[];
  abstract sortingFilterSignal: WritableSignal<ITableSortingFilter>;
  paginatedData = constructInitialSignalPaginatedResponse();
  isTableLoading = false;
  searchSignal: WritableSignal<string> = signal("");
  abstract readonly loadDataEffect$: EffectRef;
  readonly #resetPaginationOnSearchEffect$ = effect(
    () => {
      this.searchSignal();
      this.paginatedData.currentPage.set(1);
    },
    { allowSignalWrites: true },
  );
  readonly #fixStickyColumnOverflowBug = effect(() => {
    // Work around to avoid table sticking not working on sorting or searching in small screens
    // This is a bug in angular material.
    this.paginatedData.results();
    setTimeout(() => {});
  });

  abstract loadData(params: PType): void;
}
