import { IPaginatedResponse } from "../interfaces/paginated-response.interface";
import { ISignalPaginatedResponse } from "../interfaces/signal-paginated-response.interface";
import { signal, WritableSignal } from "@angular/core";
import { ISignalPaginatedSelectedResponse } from "../interfaces/signal-paginated-selected-response.interface";

export const mapToSignalPaginatedResponse = (paginatedResponse: IPaginatedResponse): ISignalPaginatedResponse => {
  return {
    currentPage: signal(paginatedResponse.currentPage),
    totalCount: signal(paginatedResponse.totalCount),
    pageSize: signal(paginatedResponse.pageSize),
    totalPages: signal(paginatedResponse.totalPages),
    results: signal(paginatedResponse.results),
  };
};

export const copyPaginatedSignalResponse = (
  paginatedSignal1: ISignalPaginatedResponse,
  paginatedSignal2: ISignalPaginatedResponse,
) => {
  paginatedSignal1.results.set(paginatedSignal2.results());
  paginatedSignal1.totalPages.set(paginatedSignal2.totalPages());
  paginatedSignal1.totalCount.set(paginatedSignal2.totalCount());
  paginatedSignal1.pageSize.set(paginatedSignal2.pageSize());
};

export const copyPaginatedSignalResponseWithSelectedUnit = (
  paginatedSignal1: ISignalPaginatedResponse,
  paginatedSignal2: ISignalPaginatedResponse,
) => {
  paginatedSignal1.results.set(paginatedSignal2.results());
  paginatedSignal1.totalPages.set(paginatedSignal2.totalPages());
  paginatedSignal1.totalCount.set(paginatedSignal2.totalCount());
  paginatedSignal1.pageSize.set(paginatedSignal2.pageSize());
};

export function constructInitialSignalPaginatedResponse<T = any>(): ISignalPaginatedResponse<T> {
  return {
    currentPage: signal(1),
    pageSize: signal(10),
    totalPages: signal(0),
    totalCount: signal(0),
    results: signal([]),
  };
}

export function constructInitialSignalPaginatedResponseWithSelected<T = any>(): ISignalPaginatedSelectedResponse<T> {
  return {
    currentPage: signal(1),
    pageSize: signal(10),
    totalPages: signal(0),
    totalCount: signal(0),
    selected: signal(0),
    results: signal([]),
  };
}

export function constructInitialSignalPaginatedResponseWithSelectedUnit<T = any>(): ISignalPaginatedResponse<T> & {
  selectedUnits: WritableSignal<number>;
} {
  return {
    currentPage: signal(1),
    pageSize: signal(10),
    totalPages: signal(0),
    totalCount: signal(0),
    selectedUnits: signal(0),
    results: signal([]),
  };
}
