import { booleanAttribute, Component, input } from "@angular/core";
import { NgClass, NgOptimizedImage } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "est-status-checkmark",
  standalone: true,
  imports: [NgOptimizedImage, TranslateModule, NgClass],
  templateUrl: "./status-checkmark.component.html",
  styleUrl: "./status-checkmark.component.scss",
})
export class StatusCheckmarkComponent {
  isApproved = input.required({
    transform: booleanAttribute,
  });
  isApprovedAltText = input("ACCESS_GRANTED");
  isDeniedAltText = input("ACCESS_DENIED");
}
