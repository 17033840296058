import { environment } from "../../../../environments/environment";

export const DocumentApis = {
  getDocumentTypes: environment.apiUrl + "Documents/GetDocumentLookups",
  organisation: {
    getAll: environment.apiUrl + "Organizations/ListOrganisationDocuments",
    uploadDocuments: environment.apiUrl + "Organizations/UploadOrganisationDocuments",
    deleteDocument: environment.apiUrl + "Organizations/DeleteOrganisationDocument",
    viewDocument: environment.apiUrl + "Organizations/GetOrganisationDocument",
    editDocument: environment.apiUrl + "Organizations/EditOrganisationDocument",
    getDocumentDetails: environment.apiUrl + "Organizations/GetOrganisationDocumentDetails",
    toggleDocumentInheritance: environment.apiUrl + "Organizations/ToggleOrganisationDocumentInheritance",
  },
  division: {
    getAll: environment.apiUrl + "Divisions/ListDivisionDocuments",
    uploadDocuments: environment.apiUrl + "Divisions/UploadDivisionDocuments",
    toggleBlockedDocument: environment.apiUrl + "Divisions/ToggleBlockedDivisionDocument",
    editDocument: environment.apiUrl + "Divisions/EditDivisionDocument",
    getDocumentDetails: environment.apiUrl + "Divisions/GetDivisionDocumentDetails",
    deleteDocument: environment.apiUrl + "Divisions/DeleteDivisionDocument",
    viewDocument: environment.apiUrl + "Divisions/GetDivisionDocument",
    toggleDocumentInheritance: environment.apiUrl + "Divisions/ToggleDivisionDocumentInheritance",
  },
  company: {
    getAll: (id: string) => environment.apiUrl + `Companies/ListCompanyDocuments/${id}`,
    toggleBlockedDocument: (id: string) => environment.apiUrl + `Companies/ToggleBlockedCompanyDocument/${id}`,
    uploadDocuments: (id: string) => environment.apiUrl + `Companies/UploadCompanyDocuments/${id}`,
    getDocumentDetails: (id: string) => environment.apiUrl + `Companies/GetCompanyDocumentDetails/${id}`,
    deleteDocument: (id: string) => environment.apiUrl + `Companies/DeleteCompanyDocument/${id}`,
    editDocument: (id: string) => environment.apiUrl + `Companies/EditCompanyDocument/${id}`,
    viewDocument: (id: string) => environment.apiUrl + `Companies/GetCompanyDocument/${id}`,
    toggleDocumentInheritance: (id: string) => environment.apiUrl + `Companies/ToggleCompanyDocumentInheritance/${id}`,
  },
  property: {
    getAll: (id: string) => environment.apiUrl + `Properties/ListPropertyDocuments/${id}`,
    uploadDocuments: (id: string) => environment.apiUrl + `Properties/UploadPropertyDocuments/${id}`,
    editDocument: (id: string) => environment.apiUrl + `Properties/EditPropertyDocument/${id}`,
    toggleDocumentInheritance: (id: string) =>
      environment.apiUrl + `Properties/TogglePropertyDocumentInheritance/${id}`,
    toggleBlockedDocument: (id: string) => environment.apiUrl + `Properties/ToggleBlockedPropertyDocument/${id}`,
    getDocumentDetails: (id: string) => environment.apiUrl + `Properties/GetPropertyDocumentDetails/${id}`,
    deleteDocument: (id: string) => environment.apiUrl + `Properties/DeletePropertyDocument/${id}`,
    viewDocument: (id: string) => environment.apiUrl + `Properties/GetPropertyDocument/${id}`,
  },
};
