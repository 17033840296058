import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { ILookUpItem } from "../models/look-up-item.model";
import { map, OperatorFunction } from "rxjs";
import { mapToSignalPaginatedResponse } from "@root/shared/utilities/signals.utilities";
import { signal } from "@angular/core";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { RoleNameMapper } from "@root/data/market/users/mappers/role-name.mapper";

export const mapLookupItemsToDropdownOptions = (lookupItems: ILookUpItem[]): IDropdownOption[] => {
  return lookupItems.map((lookupItem) => ({
    label: lookupItem.value || lookupItem.fullName!,
    value: lookupItem.id,
    roleName: RoleNameMapper.get(lookupItem.roleName),
  }));
};

export const mapLookupItemsToDropdownOptionsUnitSpecification = (lookupItems: ILookUpItem[]): IDropdownOption[] => {
  return lookupItems.map((lookupItem) => ({
    label: lookupItem.value,
    value: lookupItem.value,
  }));
};
export const mapLookupItemsToDropdownOptionsToLabelAndValue = (lookupItems: ILookUpItem[]): IDropdownOption[] => {
  return lookupItems.map((lookupItem) => ({
    label: lookupItem.value,
    value: lookupItem.id,
  }));
};

export const mapLookupItemToSignalDropdownOptionsOperator = (): OperatorFunction<
  IPaginatedResponse<ILookUpItem>,
  ISignalPaginatedResponse<IDropdownOption>
> => {
  return map((paginatedLookups) => {
    return {
      ...mapToSignalPaginatedResponse(paginatedLookups),
      results: signal(mapLookupItemsToDropdownOptions(paginatedLookups.results)),
    };
  });
};
