export enum UnitSubStatus {
  Archived = "Archived",
  NotListed = "NotListed",
  Vacant = "Vacant",
  Occupied = "Occupied",
  ReadyForSale = "ReadyForSale",
  ActiveContract = "ActiveContract",
  OngoingScreening = "OngoingScreening",
  CompletedScreening = "CompletedScreening",
  ApprovedScreening = "ApprovedScreening",
  AgreementInProcess = "AgreementInProcess",
  SigningOrderInProcess = "SigningOrderInProcess",
  SigningOrderPaused = "SigningOrderPaused",
  PendingInitialPayment = "PendingInitialPayment",
  PendingConditionsCleared = "PendingConditionsCleared",
  PendingMoveIn = "PendingMoveIn",
  Leased = "Leased",
  Sold = "Sold",
}
