import { environment } from "../../../../environments/environment";

export const RoomApis = {
  bedroom: {
    create: environment.apiUrl + "Bedrooms/CreateBedroom",
    delete: (id: number) => environment.apiUrl + `Bedrooms/DeleteBedroom/${id}`,
    getById: (id: number) => environment.apiUrl + `Bedrooms/GetBedroomById/${id}`,
    update: (id: number) => environment.apiUrl + `Bedrooms/UpdateBedroom/${id}`,
  },
  livingRoom: {
    create: environment.apiUrl + "LivingRooms/CreateLivingRoom",
    delete: (id: number) => environment.apiUrl + `LivingRooms/DeleteLivingRoom/${id}`,
    getById: (id: number) => environment.apiUrl + `LivingRooms/GetLivingRoomById/${id}`,
    update: (id: number) => environment.apiUrl + `LivingRooms/UpdateLivingRoom/${id}`,
  },
  additionalRoom: {
    create: environment.apiUrl + "AdditionalRooms/CreateAdditionalRoom",
    delete: (id: number) => environment.apiUrl + `AdditionalRooms/DeleteAdditionalRoom/${id}`,
    getById: (id: number) => environment.apiUrl + `AdditionalRooms/GetAdditionalRoomById/${id}`,
    update: (id: number) => environment.apiUrl + `AdditionalRooms/UpdateAdditionalRoom/${id}`,
  },
  restroom: {
    create: environment.apiUrl + "Restrooms/CreateRestroom",
    delete: (id: number) => environment.apiUrl + `Restrooms/DeleteRestroom/${id}`,
    getById: (id: number) => environment.apiUrl + `Restrooms/GetRestroomById/${id}`,
    update: (id: number) => environment.apiUrl + `Restrooms/UpdateRestroom/${id}`,
  },
  bathroom: {
    create: environment.apiUrl + "Bathrooms/CreateBathroom",
    delete: (id: number) => environment.apiUrl + `Bathrooms/DeleteBathroom/${id}`,
    getById: (id: number) => environment.apiUrl + `Bathrooms/GetBathroomById/${id}`,
    update: (id: number) => environment.apiUrl + `Bathrooms/UpdateBathroom/${id}`,
  },
  balcony: {
    create: environment.apiUrl + "Balconies/CreateBalcony",
    delete: (id: number) => environment.apiUrl + `Balconies/DeleteBalcony/${id}`,
    getById: (id: number) => environment.apiUrl + `Balconies/GetBalconyById/${id}`,
    update: (id: number) => environment.apiUrl + `Balconies/UpdateBalcony/${id}`,
  },
  terrace: {
    create: environment.apiUrl + "Terraces/CreateTerrace",
    delete: (id: number) => environment.apiUrl + `Terraces/DeleteTerrace/${id}`,
    getById: (id: number) => environment.apiUrl + `Terraces/GetTerraceById/${id}`,
    update: (id: number) => environment.apiUrl + `Terraces/UpdateTerrace/${id}`,
  },
  garden: {
    create: environment.apiUrl + "Gardens/CreateGarden",
    delete: (id: number) => environment.apiUrl + `Gardens/DeleteGarden/${id}`,
    getById: (id: number) => environment.apiUrl + `Gardens/GetGardenById/${id}`,
    update: (id: number) => environment.apiUrl + `Gardens/UpdateGarden/${id}`,
  },
  internalStorageRoom: {
    create: environment.apiUrl + "InternalStorageRooms/CreateInternalStorageRoom",
    delete: (id: number) => environment.apiUrl + `InternalStorageRooms/DeleteInternalStorageRoom/${id}`,
    getById: (id: number) => environment.apiUrl + `InternalStorageRooms/GetInternalStorageRoomById/${id}`,
    update: (id: number) => environment.apiUrl + `InternalStorageRooms/UpdateInternalStorageRoom/${id}`,
  },
};
