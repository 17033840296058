import { environment } from "../../../../environments/environment";

export const FollowUpTaskApis = {
  organisation: {
    getFollowUpTask: environment.apiUrl + "Organizations/GetOrganisationFollowUpTask",
    updateFollowUpTask: environment.apiUrl + "Organizations/UpdateOrganisationFollowUpTask",
    createFollowUpTask: environment.apiUrl + "Organizations/CreateOrganisationFollowUpTask",
    toggleFollowUpTaskInheritance: environment.apiUrl + "Organizations/ToggleOrganisationFollowUpTaskInheritance",
    deleteFollowUpTask: environment.apiUrl + "Organizations/DeleteOrganisationFollowUpTask",
    getAllFollowUpTasks: environment.apiUrl + `Organizations/ListOrganisationFollowUpTasks`,
  },
};
