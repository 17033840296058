import { CdkDrag, CdkDropList, moveItemInArray } from "@angular/cdk/drag-drop";
import { DatePipe, NgClass, NgComponentOutlet, NgOptimizedImage } from "@angular/common";
import {
  booleanAttribute,
  Component,
  computed,
  effect,
  EventEmitter,
  Input,
  input,
  InputSignal,
  numberAttribute,
  OnInit,
  output,
  Output,
  signal,
  ViewChild,
  WritableSignal,
} from "@angular/core";
import { FormControl, FormsModule } from "@angular/forms";
import { MatButton } from "@angular/material/button";
import { MatCheckbox } from "@angular/material/checkbox";
import { MatIcon } from "@angular/material/icon";
import { MatMenu, MatMenuItem, MatMenuTrigger } from "@angular/material/menu";
import { MatRadioButton } from "@angular/material/radio";
import { MatSlideToggle, MatSlideToggleChange } from "@angular/material/slide-toggle";
import { MatCell, MatTable, MatTableModule } from "@angular/material/table";
import { MatTooltip } from "@angular/material/tooltip";
import { TranslateModule } from "@ngx-translate/core";
import { PartialTranslatePipe } from "@root/core/pipes/partial-translate.pipe";
import { TruncatePipe } from "@root/core/pipes/truncate.pipe";
import { UnitSubStatusTranslationMapper } from "@root/data/market/units/mappers/unit-substatus-translation.mapper";
import { getUnitSubStatusClasses } from "@root/data/market/units/utilities/residential-units.utilities";
import { SpinnerComponent } from "@root/shared/spinner/spinner.component";
import { StatusCheckmarkComponent } from "@root/shared/status-checkmark/status-checkmark.component";
import { CustomDatePipe } from "../../core/pipes/custom-date.pipe";
import { BaseTableComponent } from "../abstracts/base-table/base-table.component";
import { ISmallListTableInput } from "../interfaces/small-list-table-input.interface";
import { LoadingOverlayComponent } from "../loading-overlay/loading-overlay.component";
import { isDateValid } from "../utilities/date.utilities";

@Component({
  selector: "est-small-list-table",
  standalone: true,
  imports: [
    MatCell,
    MatTable,
    CdkDropList,
    MatCheckbox,
    MatSlideToggle,
    FormsModule,
    MatTableModule,
    CdkDrag,
    NgOptimizedImage,
    TranslateModule,
    DatePipe,
    MatMenu,
    MatMenuTrigger,
    MatButton,
    MatIcon,
    MatMenuItem,
    NgClass,
    NgComponentOutlet,
    CustomDatePipe,
    MatRadioButton,
    LoadingOverlayComponent,
    PartialTranslatePipe,
    StatusCheckmarkComponent,
    SpinnerComponent,
    TruncatePipe,
    MatTooltip,
  ],
  templateUrl: "./small-list-table.component.html",
  styleUrl: "./small-list-table.component.scss",
})
export class SmallListTableComponent extends BaseTableComponent implements OnInit {
  override data = input.required<ISmallListTableInput[]>();
  isDraggable = input(false, { transform: booleanAttribute });
  iconMenuComponentType = input<any>();
  radioButtonControl: InputSignal<FormControl<number | null>> = input(new FormControl<number | null>(null));
  radioGroupHashMap = input<Map<number, string>>();
  appliedClasses = input("");
  hasConfirmationCheckbox = input(false, { transform: booleanAttribute });
  hasSchedule = input(false, { transform: booleanAttribute });
  isScheduleDisabled = input(false, { transform: booleanAttribute });
  extraRows = input(0, { transform: numberAttribute });
  extraRowsLabel = input("");
  confirmationCheckboxPropertyName = input("");
  schedule = output<ISmallListTableInput>();
  confirmToggle = output<ISmallListTableInput>();
  confirmDelete = output<ISmallListTableInput>();

  @Input() isPopupIconAvailable?: (item: ISmallListTableInput) => boolean;
  @Input() isMouseoverIconVisibleFn?: (item: ISmallListTableInput) => boolean;
  @Input() showOrHideLoaderOnClickedRow: WritableSignal<null | number> = signal(null);
  @ViewChild("table") table!: MatTable<HTMLTableElement>;

  @Output() dragAndReorder: EventEmitter<{ [key: string]: any }> = new EventEmitter();
  @Output() selectRadioItem: EventEmitter<{ row: any }> = new EventEmitter();
  itemPopup = output<ISmallListTableInput>();
  selectRadioGroup = output<{ key: string; row: ISmallListTableInput }>();
  heart = output<any>();
  isDragDropListDisabled = true;
  toggleButtonDisableHashMap = input<Map<number, boolean>>(); // Set to true if disabled
  extraRowsArray = computed(() =>
    Array.from({
      length: this.extraRows(),
    }),
  );

  protected readonly getUnitSubStatusClasses = getUnitSubStatusClasses;
  protected readonly UnitSubStatusTranslationMapper = UnitSubStatusTranslationMapper;
  protected readonly isDateValid = isDateValid;
  protected readonly unitSubStatusTranslationMapper = UnitSubStatusTranslationMapper;

  readonly #addMarkedCheckboxesToSelectionEffect$ = effect(() => {
    this.addMarkedCheckboxesToSelection();
  });

  override ngOnInit() {
    super.ngOnInit();
  }

  dragAndDrop(event: any) {
    moveItemInArray(this.data(), event.previousIndex, event.currentIndex);
    const updatedRows = this.data().map((row) => {
      return {
        ...row,
        order: this.data().findIndex((r) => r === row) + 1,
      };
    });
    this.table.renderRows();
    this.dragAndReorder.emit({ rows: updatedRows });
  }

  toggleRow(event: MatSlideToggleChange, row: any) {
    row.active = event.checked;
    this.toggleItem.emit({ checked: event.checked, row });
  }

  emitRadioSelectedRow(row: ISmallListTableInput) {
    if (!row) return;
    this.radioButtonControl().setValue(row.id);
    this.selectRadioItem.emit({ row: row });
  }

  radioButtonLabel(row: ISmallListTableInput): string {
    return this.radioButtonControl().value === row.id ? "TABLE.DESELECT_ROW" : "TABLE.SELECT_ROW";
  }

  isRadioGroupKeySelected(radioGroupValue: string, row: ISmallListTableInput): boolean {
    if (!this.radioGroupHashMap()) return false;
    return this.radioGroupHashMap()!.get(row.id) === radioGroupValue;
  }

  isRadioButtonChecked(row: ISmallListTableInput) {
    if (!this.radioButtonControl().value) return;
    return this.radioButtonControl().value === row.id;
  }

  updateRadioGroupControl(radioGroupValue: string, row: ISmallListTableInput): void {
    if (!this.radioGroupHashMap()) return;
    this.radioGroupHashMap()!.set(row.id, radioGroupValue);
  }

  emitValueAndViewDocument(row: ISmallListTableInput) {
    this.itemPopup.emit(row);
    this.showOrHideLoaderOnClickedRow.set(row.id);
  }
}
