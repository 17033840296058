import { booleanAttribute, Component, input } from "@angular/core";

import { NgClass, NgOptimizedImage } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { ImagePositionType } from "@root/shared/button/image-position.type";
import { SpinnerComponent } from "../spinner/spinner.component";
import { ButtonStyleType } from "./button-style.type";

@Component({
  selector: "est-button",
  standalone: true,
  imports: [NgClass, TranslateModule, NgOptimizedImage, SpinnerComponent],
  templateUrl: "./button.component.html",
  styleUrl: "./button.component.scss",
})
export class ButtonComponent {
  isLoading = input(undefined, { transform: booleanAttribute });
  disabled = input(false, {
    transform: booleanAttribute,
  });
  label = input.required<string>();
  labelCaseSensitiveClass = input<string>("uppercase");
  imageSrc = input<string>();
  imageAlt = input<string>();
  appliedClasses = input<string>("");
  appliedSpinnerClasses = input<string>("!fill-white");
  buttonStyleType = input.required<ButtonStyleType>();
  imagePosition = input<ImagePositionType>("before");
  buttonType = input.required<string>();
}
