export const removeNullsFromObject = (obj: { [key: string]: any }): { [key: string]: any } => {
  if (obj && typeof obj === "object") {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (typeof value === "object" && value !== null && value !== undefined) {
        // Recursively call the function for nested objects or arrays
        removeNullsFromObject(value);
      } else if (value === null || value === undefined) {
        delete obj[key];
      }
    });
  }
  return obj;
};

export const setEmptyStringsToNull = (obj: { [key: string]: any }): { [key: string]: any } => {
  if (obj && typeof obj === "object") {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (typeof value === "object" && value !== null) {
        // Recursively call the function for nested objects or arrays
        setEmptyStringsToNull(value);
      } else if (value === "") {
        // Set empty string values to null
        obj[key] = null;
      }
    });
  }
  return obj;
};
