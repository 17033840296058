<div class="w-4 h-4">
  <img
    [alt]="isApprovedAltText() | translate"
    [ngClass]="{ hidden: !isApproved() }"
    draggable="false"
    height="16"
    ngSrc="assets/images/icons/status-approved.svg"
    priority
    width="16"
  />
  <img
    [alt]="isDeniedAltText() | translate"
    [ngClass]="{ hidden: isApproved() }"
    draggable="false"
    height="16"
    ngSrc="assets/images/icons/status-denied.svg"
    priority
    width="16"
  />
</div>
