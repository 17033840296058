import { environment } from "../../../../environments/environment";

export const CustomerCategoryApis = {
  organisation: {
    getCustomerCategory: environment.apiUrl + "Organizations/GetOrganisationCustomerCategory",
    createCustomerCategory: environment.apiUrl + "Organizations/CreateOrganisationCustomerCategory",
    updateCustomerCategory: environment.apiUrl + "Organizations/UpdateOrganisationCustomerCategory",
    getAllCustomerCategories: environment.apiUrl + "Organizations/ListOrganisationCustomerCategories",
    deleteCustomerCategory: environment.apiUrl + "Organizations/DeleteOrganisationCustomerCategory",
    toggleCustomerCategoryInheritance:
      environment.apiUrl + "Organizations/ToggleOrganisationCustomerCategoryInheritance",
    lookUpCustomerCategories: environment.apiUrl + "Organizations/LookUpOrganisationCustomerCategories",
  },
};
