import { UnitSubStatus } from "@root/data/market/units/enums/unit-substatus.enum";

export const UnitSubStatusTranslationMapper = new Map<UnitSubStatus, string>([
  [UnitSubStatus.ActiveContract, "UNIT_SUB-STATUS.ACTIVE_CONTRACT"],
  [UnitSubStatus.Archived, "UNIT_SUB-STATUS.ARCHIVED"],
  [UnitSubStatus.NotListed, "UNIT_SUB-STATUS.NOT_LISTED"],
  [UnitSubStatus.Vacant, "UNIT_SUB-STATUS.VACANT"],
  [UnitSubStatus.Occupied, "UNIT_SUB-STATUS.OCCUPIED"],
  [UnitSubStatus.ReadyForSale, "UNIT_SUB-STATUS.READY_FOR_SALE"],
  [UnitSubStatus.ActiveContract, "UNIT_SUB-STATUS.ACTIVE_CONTRACT"],
  [UnitSubStatus.OngoingScreening, "UNIT_SUB-STATUS.ONGOING_SCREENING"],
  [UnitSubStatus.CompletedScreening, "UNIT_SUB-STATUS.COMPLETED_SCREENING"],
  [UnitSubStatus.ApprovedScreening, "UNIT_SUB-STATUS.APPROVED_SCREENING"],
  [UnitSubStatus.AgreementInProcess, "UNIT_SUB-STATUS.AGREEMENT_IN_PROCESS"],
  [UnitSubStatus.SigningOrderInProcess, "UNIT_SUB-STATUS.SIGNING_ORDER_IN_PROCESS"],
  [UnitSubStatus.SigningOrderPaused, "UNIT_SUB-STATUS.SIGNING_ORDER_PAUSED"],
  [UnitSubStatus.PendingInitialPayment, "UNIT_SUB-STATUS.PENDING_INITIAL_PAYMENT"],
  [UnitSubStatus.PendingConditionsCleared, "UNIT_SUB-STATUS.PENDING_CONDITIONS_CLEARED"],
  [UnitSubStatus.PendingMoveIn, "UNIT_SUB-STATUS.PENDING_MOVE_IN"],
  [UnitSubStatus.Leased, "UNIT_SUB-STATUS.LEASED"],
  [UnitSubStatus.Sold, "UNIT_SUB-STATUS.SOLD"],
]);
