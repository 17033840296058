import { environment } from "../../../../environments/environment";

export const MarketingApis = {
  organisation: {
    createMarketingQuestion: environment.apiUrl + "Organizations/CreateOrganisationMarketingQuestion",
    getMarketingQuestion: environment.apiUrl + `Organizations/GetOrganisationMarketingQuestion`,
    updateMarketingQuestion: environment.apiUrl + `Organizations/UpdateOrganisationMarketingQuestion`,
    getAllMarketingQuestions: environment.apiUrl + `Organizations/ListOrganisationMarketingQuestions`,
    toggleMarketingQuestionInheritance:
      environment.apiUrl + "Organizations/ToggleOrganisationMarketingQuestionInheritance",
    deleteMarketingQuestion: environment.apiUrl + "Organizations/DeleteOrganisationMarketingQuestion",
    toggleBlockedMarketingQuestion: environment.apiUrl + "Organizations/ToggleBlockedOrganisationMarketingQuestion",
  },
};
