import { environment } from "../../../../environments/environment";

export const FollowUpStatusApis = {
  organisation: {
    getFollowUpStatus: environment.apiUrl + "Organizations/GetOrganisationFollowUpStatus",
    getAllFollowUpStatus: environment.apiUrl + "Organizations/ListOrganisationFollowUpStatus",
    updateFollowUpStatus: environment.apiUrl + "Organizations/UpdateOrganisationFollowUpStatus",
    deleteFollowUpStatus: environment.apiUrl + "Organizations/DeleteOrganisationFollowUpStatus",
    createFollowUpStatus: environment.apiUrl + "Organizations/CreateOrganisationFollowUpStatus",
    toggleFollowUpStatusInheritance: environment.apiUrl + "Organizations/ToggleOrganisationFollowUpStatus",
  },
};
